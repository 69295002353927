import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";


export const frontmatter = {
  title: "Día 61",
  week: "Semana 9",
  day: "13",
  month: "may",
  monthNumber: "05",
  date: "2020-05-13",
  path: "/cronologia/semana-09#dia-13-may",
};
const Day61 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En España, el balance del día se salda con el registro de 1.377 nuevos
          positivos y 176 personas fallecidas. Permanecen hospitalizadas 123.484
          personas, 513 más que el día anterior, y 1.841 reciben el alta. El
          número de casos ingresados en la UCI asciende a 11.371, lo que supone
          un aumento de 23 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 51,6 %.
        </ModText>
        <ModText>
          El <strong>Instituto de Agroquímica y Tecnología de Alimentos</strong>
          , centro de investigación del Consejo Superior de Investigaciones
          Científicas (CSIC), está llevando a cabo un proyecto que tiene como
          objetivo el{" "}
          <strong>desarrollo de materiales biodegradables y viricidas</strong>{" "}
          para dispositivos sanitarios reutilizables de alta protección.
        </ModText>
        <ModImage src="/images/svg/13_may_prisiones.svg" alt="prisiones" />
        <ModText>
          El <strong>Ministerio de Interior</strong> ha dictado una orden por la
          que se acuerda la flexibilización "paulatina y gradual" de las medidas
          adoptadas frente al <strong>COVID-19 en las prisione</strong>s de toda
          España. Entre ellas, se encuentran las salidas de permiso, traslado de
          internos y actividades en el interior de los centros.
        </ModText>
        <ModImage
          src="https://coronavirus.secuoyas.com/svg/13_may_libro-electronico.svg"
          alt="libro electrónico"
        />
        <ModText>
          El Ministerio de Cultura y Deporte ha realizado la compra
          extraordinaria de cerca de 60.000 licencias de nuevos títulos para{" "}
          <InlineLink link="https://www.culturaydeporte.gob.es/cultura/areas/bibliotecas/mc/eBiblio/inicio.html">
            eBiblio
          </InlineLink>
          , el servicio de préstamo gratuito de libros electrónicos de las
          bibliotecas públicas. Esta adquisición, responde al fuerte incremento
          de uso de eBiblio durante el estado de alarma, con el objetivo de
          facilitar el acceso a este servicio a un mayor número de ciudadanos.
        </ModText>
        <ModText>
          El{" "}
          <strong>
            Ministerio de Transportes, Movilidad y Agenda Urbana (Mitma)
          </strong>{" "}
          ha transferido, de manera adelantada, más de 446 millones de euros a
          las comunidades autónomas y a las ciudades autónomas de Ceuta y
          Melilla para la ejecución de los programas de ayuda del{" "}
          <strong>Plan Estatal de Vivienda 2018-2021. </strong>
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mitma.gob.es/arquitectura-vivienda-y-suelo/programas-de-ayudas-a-la-vivienda"
            name="Plan Estatal de Vivienda 2018-2021"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day61;
